import React, { useMemo } from 'react';
import { BrowserRouter, Route, Routes, useParams, useLocation } from "react-router-dom";

import ShopApp from '../../containers/ShopApp';
import NoMatch from '../NoMatch';
import ErrorBoundary from '../ErrorBoundary';

const AppRoute = React.memo(({ panel, modal }) => {
  const params = useParams();
  const location = useLocation();

  const shopParams = useMemo(
    () => {
      if (params?.item_id) {
        return { ...params, order_number: params?.shop_number };
      }
      return params;
    },
    [params]
  );

  if (panel || modal) {
    return <ShopApp panel={panel} modal={modal} params={shopParams} location={location} />;
  }

  return <NoMatch location={location} />;
});

const ShopRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/shop/:shop_number"
        element={<AppRoute panel="config" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/storefront"
        element={<AppRoute panel="storefront" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/products"
        element={<AppRoute panel="products" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/products/add-product"
        element={<AppRoute modal="search" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/products/:item_id"
        element={<AppRoute modal="item" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/inventory"
        element={<AppRoute panel="inventory" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/staging"
        element={<AppRoute panel="staging" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_number/orders"
        element={<AppRoute panel="orders" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default ShopRouter;
